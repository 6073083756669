import { useCookies } from 'react-cookie';
import config from 'config';
import NotificationBox from 'components/NotificationBox';
import WebComponent from 'utils/web-component';
import { useEffect, useState } from 'react';
import classNames from 'classnames';

type GardePromoProps = {
  site: Site;
  inbox: number | undefined;
};

const COOKIE_NAME = 'notification-dismissed--garde-promo';
const COOKIE_NAME_CLOSED_TWICE = 'notification-dismissed--garde-promo--closed-twice';

const daysToExpiryTime = function (days: number | 'indefinite') {
  days = days === 'indefinite' ? 400 : days;
  // 400 = maximum allowed. Quota will likely be used at this point, otherwise it might be a good time to show alert again.
  return new Date(Date.now() + days * 24 * 60 * 60 * 1000);
};

export default function GardePromo({ site, inbox }: GardePromoProps) {
  const [notificationShown, setNotificationShown] = useState<boolean>(false);
  const [notificationFaded, setNotificationFaded] = useState<boolean>(false);
  const [cookies, setCookie] = useCookies();

  useEffect(() => {
    if (!cookies[COOKIE_NAME] && inbox && hasItemsToModerate(site, inbox)) {
      setNotificationShown(true);
      setNotificationFaded(false);
    } else if (!cookies[COOKIE_NAME] && hasStartedModeration(site)) {
      // has started to moderate, set cookie (again if expired)
      setPromoCookie('indefinite');
    }
  }, [cookies, site]);

  const hasItemsToModerate = (site: Site, inbox: number) => {
    return (
      site.ai_moderation_enabled && site.ai_moderation_quota_left === site.ai_moderation_quota && inbox && inbox > 3
    );
  };

  const hasStartedModeration = (site: Site) => {
    return (
      site.ai_moderation_quota_left &&
      site.ai_moderation_quota &&
      site.ai_moderation_quota_left < site.ai_moderation_quota
    );
  };

  const dismissPromo = (days: number, onceOnly?: boolean) => {
    setNotificationFaded(true);
    setTimeout(() => setNotificationShown(false), 1000);
    setPromoCookie(days, onceOnly);
  };

  const setPromoCookie = (days: number | 'indefinite', onceOnly?: boolean) => {
    let expireTime = daysToExpiryTime(days);

    if (onceOnly && cookies[COOKIE_NAME_CLOSED_TWICE]) {
      // close from X for the second time, do not show 'ever' again
      expireTime = daysToExpiryTime('indefinite');
    } else if (onceOnly) {
      // set cookie for having closed from X once
      setCookie(COOKIE_NAME_CLOSED_TWICE, 'true', {
        expires: daysToExpiryTime('indefinite'),
        path: '/',
        domain: config.flocklerBaseDomain,
      });
    }

    setCookie(COOKIE_NAME, 'true', {
      expires: expireTime,
      path: '/',
      domain: config.flocklerBaseDomain,
    });
  };

  return (
    <div
      className={classNames('duration-[800ms]', notificationFaded ? 'margin-0 max-h-0 opacity-0' : 'max-h-[1200px]')}
    >
      {notificationShown && (
        <NotificationBox
          title={
            <>
              Meet{' '}
              <WebComponent
                tag="fl-icon"
                icon="garde-text"
                color="currentColor"
                width="6em"
                height="auto"
                class="relative top-[.25em] -mb-2 -mt-2 inline-block align-middle"
              >
                Garde AI
              </WebComponent>{' '}
              — Flockler’s new content moderator{' '}
            </>
          }
          severity="promo"
          icon="garde"
          dismissAction={() => {
            dismissPromo(30, true);
          }} /* try again in a month, if not taken into use */
          actions={
            <>
              <WebComponent
                tag="fl-button"
                class="mt mr tight-mobile"
                onClick={() => {
                  dismissPromo(365 * 10);
                  window.location.href = `${config.flocklerAppUrl}/${site.site_url}/sections?intro=garde`;
                }}
              >
                Try it now
              </WebComponent>
              <WebComponent
                tag="fl-button"
                variant="plain"
                class="mt tight-mobile"
                onClick={() => {
                  dismissPromo(7);
                }}
              >
                Remind me later
              </WebComponent>
            </>
          }
        >
          <p>Designed to save you time, filter out spammy/off-brand content, and manage your feeds in real-time.</p>
        </NotificationBox>
      )}
    </div>
  );
}
