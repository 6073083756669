import Icon from 'components/Icon';
import config from 'config';
import { getGravatarUrl } from 'services/gravatar';
import WebComponent from 'utils/web-component';

type AccountRelayAccountProps = {
  userDetails: User;
};

const AccountRelayAccount = ({ userDetails }: AccountRelayAccountProps) => {
  const name =
    !userDetails.firstname && !userDetails.lastname
      ? userDetails.username
      : [userDetails.firstname, userDetails.lastname].filter(Boolean).join(' ');

  return (
    <div className="rounded-lg border border-slate-200 bg-slate-50 bg-opacity-50 p-6 shadow-sm">
      <div className="flex flex-col items-center gap-4 text-center md:flex-row md:gap-6 md:text-left">
        <div className="flex-shrink-0">
          <img
            className="h-12 w-12 rounded-full"
            srcSet={`
            ${getGravatarUrl(userDetails.email, 48)} 48w,
            ${getGravatarUrl(userDetails.email, 96)} 96w
          `}
            alt=""
          />
        </div>
        <div className="flex-shrink">
          <h3 className="text-lg font-bold text-slate-800">{name}</h3>

          <p className="text-sm text-slate-600">Account details are managed by your Relay account.</p>
        </div>
        <div className="flex-shrink-0 md:ml-auto">
          <WebComponent
            class="inline-block"
            tag="fl-button"
            target="_blank"
            href={`${config.authFrontendUrl}/flockler/account`}
          >
            Manage Relay account <Icon type="external-link" />
          </WebComponent>
        </div>
      </div>
    </div>
  );
};

export default AccountRelayAccount;
