// # Subscription Overview view-concept

import { createSelector } from '@reduxjs/toolkit';
import get from 'lodash/get';
import { getHasLoadedStripeSubscription } from 'concepts/stripe-subscription';
import {
  StripeInvoice,
  getStripeUpcomingInvoice,
  getHasLoadedStripeUpcomingInvoice,
} from 'concepts/stripe-upcoming-invoice';
import { getSubscription, isTrialSubscribed, Subscription } from 'concepts/subscription';
import {
  getAddons,
  getAddonTypes,
  getHasLoadedAddons,
  getHasLoadedAddonTypes,
  Addon,
  AddonType,
  AddonState,
} from 'concepts/addon';
import { getCurrentSite } from 'concepts/site';
import { getAvailablePlans, AvailablePlans, Plan } from 'concepts/plan';
import { getIsYearlyPlan } from 'services/plan';

// All loading flags for price calculation
export const getHasLoadedPrice = createSelector(
  getHasLoadedAddons,
  getHasLoadedAddonTypes,
  getHasLoadedStripeSubscription,
  getHasLoadedStripeUpcomingInvoice,
  (hasLoadedAddons, hasLoadedAddonTypes, getHasLoadedStripeSubscription, getHasLoadedStripeUpcomingInvoice) => {
    return [hasLoadedAddons, hasLoadedAddonTypes, getHasLoadedStripeSubscription, getHasLoadedStripeUpcomingInvoice]
      .every((hasLoaded) => hasLoaded);
  }
);

const getSubscriptionPlan = (plans: AvailablePlans, subscription: Subscription) => {
  const allPlans = [...plans.monthly, ...plans.yearly];
  const subscriptionPlan = subscription.next_plan || subscription.plan;
  return allPlans.find((plan: Plan) => plan.id === subscriptionPlan);
};

export const getActiveAddonsPrices = createSelector(
  getCurrentSite,
  getSubscription,
  getAddons,
  getAddonTypes,
  (site: Site, subscription: Subscription, addons?: Addon[], addonTypes?: AddonType[]) => {
    if (!addons || !addonTypes) {
      return 0;
    }

    const plan = subscription.next_plan || subscription.plan;
    const isYearlyPlan = getIsYearlyPlan(plan);

    const addonStatesAffectingPrice = [AddonState.active];
    if (isTrialSubscribed(subscription)) {
      addonStatesAffectingPrice.push(AddonState.trial);
    }

    const activeAddons = addons.filter((addon: Addon) => addonStatesAffectingPrice.includes(addon.state));
    const pricePeriodKey = isYearlyPlan ? 'yearly' : 'monthly';

    return activeAddons.reduce((sum: number, addon: Addon) => {
      const addonType = addonTypes.find((addonType: AddonType) => addonType.addon_type === addon.addon_type);

      if (!addonType) {
        return sum;
      }

      const currency = site.currency || 'EUR';
      const addonPrice = get(addonType, ['prices', pricePeriodKey, currency.toLowerCase()]);

      return sum + addonPrice;
    }, 0);
  }
);

export const getSubscriptionPrice = createSelector(
  getAvailablePlans,
  getSubscription,
  (availablePlans: AvailablePlans, subscription: Subscription) => {
    const plan = getSubscriptionPlan(availablePlans, subscription);

    if (!plan) {
      return 0;
    }

    const isYearlyPlan = getIsYearlyPlan(plan.id);
    return isYearlyPlan ? plan.price_per_year : plan.price_per_month;
  }
);

export const getStripeSubscriptionInvoiceItems = createSelector(
  getStripeUpcomingInvoice,
  (invoice: StripeInvoice) => {
    return invoice?.items || [];
  }
);

// Calculate total price including main plan + addons
export const getTotalSubscriptionPrice = createSelector(
  getSubscriptionPrice,
  getActiveAddonsPrices,
  getStripeUpcomingInvoice,
  (mainPlanPrice, addonsPrice, invoice) => {
    if (invoice?.amount_due) {
      return invoice.amount_due;
    }

    return mainPlanPrice + addonsPrice;
  }
);

const getAddonType = (addonName: string) =>
  createSelector(getAddonTypes, (addonTypes?: AddonType[]) => {
    return (addonTypes || []).find((addonType: AddonType) => addonType.addon_type === addonName);
  });

export const getAnalyticsAddonType = getAddonType('social_analytics');
export const getAnalyticsAddon = createSelector(getAddons, (addons?: Addon[]) =>
  (addons || []).find((addon: Addon) => addon.addon_type === 'social_analytics')
);
