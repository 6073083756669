import React, { useMemo, useState } from 'react';
import classnames from 'classnames';
import get from 'lodash/get';
import uniq from 'lodash/uniq';
import config from 'config';
import useSiteUrl from 'hooks/useSiteUrl';
import { formatRelativeDistance } from 'services/time';

import AutomatedFeedsListItemIcon from '../AutomatedFeedsListItemIcon';
import AutomatedFeedsListItemSummary from '../AutomatedFeedsListItemSummary';
import AutomatedFeedError from '../AutomatedFeedError';
import styles from './AutomatedFeedsListItem.module.scss';
import { ReactComponent as IconPause } from 'images/icons/icon-pause.svg';
import { ReactComponent as IconPlay } from 'images/icons/icon-play.svg';
import { ReactComponent as IconRemove } from 'images/icons/icon-remove-circle.svg';
import { ReactComponent as IconArrowRight } from 'images/icons/icon-angle-right.svg';

const getParameterFunc = (name: string) => {
  return function (filter: any) {
    return filter.parameters.reduce(function (currentParams: any, parameter: any) {
      return parameter.name === name ? (currentParams + ' ' + parameter.value).trim() : currentParams;
    }, '');
  };
};

const findFilterByType = (filters: MediaTrackerFilter[], type: MediaTrackerFilterParameterType) =>
  filters.find((filter) => filter.filter_type === type);

const findParameterByName = (filter: MediaTrackerFilter, name: string) =>
  filter.parameters.find((parameter: MediaTrackerParameter) => parameter.name === name);

const getInstagramEndpointPrefix = (filter: MediaTrackerFilter) => {
  const endpointsParameter = findParameterByName(filter, 'endpoints');
  const endpointsValue = endpointsParameter?.value;

  if (endpointsValue === 'stories') {
    return 'Stories from ';
  }

  if (endpointsValue === 'mentions') {
    return 'Mentions of ';
  }

  return '';
};

const getFacebookEndpointsVisibleName = (filter: MediaTrackerFilter) => {
  const facebookEndpointsStr = findParameterByName(filter, 'endpoints')?.value as string;
  const facebookFeedType = findParameterByName(filter, 'type')?.value;

  if (!facebookEndpointsStr) return '';

  let effectiveEndpointsStr = facebookEndpointsStr;

  if (facebookFeedType === 'page') {
    effectiveEndpointsStr = facebookEndpointsStr.replace('feed', 'posts,visitor_posts');
  }

  const endpoints = uniq(effectiveEndpointsStr.split(','));

  if (endpoints.length === 1 && endpoints[0] === 'ratings') {
    return ' reviews';
  }

  let sentence;
  const visibleNames = endpoints
    .map(function (endpoint) {
      if (endpoint === 'posts') {
        return 'by page admins';
      } else if (endpoint === 'visitor_posts') {
        return 'by page visitors';
      } else if (endpoint === 'tagged') {
        return 'where the page is mentioned';
      } else if (endpoint === 'ratings') {
        return 'with a review';
      }
      return '';
    })
    .sort();

  if (visibleNames.length === 0) {
    sentence = '';
  } else if (visibleNames.length === 1) {
    sentence = visibleNames[0];
  } else {
    sentence = visibleNames.slice(0, -1).join(', ') + ' and ' + visibleNames[visibleNames.length - 1];
  }

  return ', Posts ' + sentence;
};

const getUserVisibleName = (filter: MediaTrackerFilter) => {
  if (!filter.parameters) {
    return null;
  }

  const usernameParameter = findParameterByName(filter, 'username');
  if (usernameParameter?.value) {
    return '@' + usernameParameter.value;
  }

  const visibleNameParameter = findParameterByName(filter, 'visible_name');
  if (visibleNameParameter?.value) {
    return visibleNameParameter.value;
  }

  const nameParameter = findParameterByName(filter, 'name');
  if (nameParameter?.value) {
    return '@' + nameParameter.value;
  }

  return null;
};

const getAuthorVisibleName = (filter: MediaTrackerFilter) => {
  if (!filter.parameters) {
    return null;
  }

  const visibleNameParameter = findParameterByName(filter, 'visible_name');
  if (visibleNameParameter?.value) {
    return visibleNameParameter.value;
  }

  const usernameParameter = findParameterByName(filter, 'username');
  if (usernameParameter?.value) {
    return '@' + usernameParameter.value;
  }

  const nameParameter = findParameterByName(filter, 'name');
  if (nameParameter?.value) {
    return '@' + nameParameter.value;
  }

  return null;
};

const getTwitterAdditionalSettingsVisibleName = (feed: MediaTracker) => {
  let visibleName = '';

  const noRepliesFilter = findFilterByType(feed.media_tracker_filters, 'no_replies');
  const noRepublishedMediaFilter = findFilterByType(feed.media_tracker_filters, 'no_republished_media');
  const picturesOnlyFilter = findFilterByType(feed.media_tracker_filters, 'pictures_only');
  const videosOnlyFilter = findFilterByType(feed.media_tracker_filters, 'videos_only');

  if (!noRepliesFilter) {
    visibleName += ', include replies';
  }

  if (!noRepublishedMediaFilter) {
    visibleName += ', include retweets';
  }

  if (videosOnlyFilter && picturesOnlyFilter) {
    visibleName += ', only images and videos';
  } else if (picturesOnlyFilter) {
    visibleName += ', only images';
  } else if (videosOnlyFilter) {
    visibleName += ', only videos';
  }

  return visibleName;
};

const getCategoryByAuthorVisibleName = (filter: MediaTrackerFilter) => {
  return filter.parameters.reduce((currentParams, parameter) => {
    return parameter.name === 'category_name' ? (currentParams + ' ' + parameter.value).trim() : currentParams;
  }, '');
};

const getKeywordParameterVisibleName = (filter: MediaTrackerFilter) => {
  const keywords = filter.parameters
    .filter((parameter) => parameter.name === 'keyword')
    .map((parameter) => parameter.value);

  return keywords?.length > 0 ? ', ' + keywords.join(', ') : '';
};

const getMediaTypeFilterName = (filter: MediaTrackerFilter) => {
  const mediaType = findParameterByName(filter, 'media_type');

  if (mediaType?.value === 'video') return 'only videos';
  if (mediaType?.value === 'image') return 'only images';

  return null;
};

const filterTypesForVisibleName = {
  match_author: {
    getVisibleName: (filter: MediaTrackerFilter, feed: MediaTracker) => {
      const instagramEndpointPrefix = getInstagramEndpointPrefix(filter);
      let visibleName;

      if (instagramEndpointPrefix) {
        return instagramEndpointPrefix + getUserVisibleName(filter) + getKeywordParameterVisibleName(filter);
      }

      visibleName = getAuthorVisibleName(filter) + getKeywordParameterVisibleName(filter);

      if (feed.service === 'facebook') {
        visibleName += getFacebookEndpointsVisibleName(filter);
      }

      if (
        ['twitter', 'twitter_v2'].includes(feed.service) &&
        !findFilterByType(feed.media_tracker_filters, 'match_tag')
      ) {
        visibleName += getTwitterAdditionalSettingsVisibleName(feed);
      }

      return visibleName;
    },
    maxInName: 1,
  },
  match_business_author: {
    getVisibleName: function (filter: MediaTrackerFilter) {
      return getAuthorVisibleName(filter);
    },
    maxInName: 1,
  },
  match_simple_author: {
    getVisibleName: function (filter: MediaTrackerFilter) {
      return getAuthorVisibleName(filter);
    },
    maxInName: 1,
  },
  match_tag: {
    getVisibleName: function (filter: MediaTrackerFilter, feed: MediaTracker) {
      var visibleName = getParameterFunc('name')(filter);

      if (['twitter', 'twitter_v2'].includes(feed.service)) {
        if (!visibleName.match(/^#/)) {
          visibleName = `Keyword: ${visibleName}`;
        }

        visibleName += getTwitterAdditionalSettingsVisibleName(feed);
      }

      if (feed.service === 'youtube') {
        if (!visibleName.match(/^#/)) {
          visibleName = `Keyword: ${visibleName}`;
        }
      }

      return visibleName;
    },
    maxInName: 2,
  },
  match_media_type: {
    getVisibleName: getMediaTypeFilterName,
    maxInName: 1,
  },
  match_category_by_author: {
    getVisibleName: function (filter: MediaTrackerFilter) {
      return getCategoryByAuthorVisibleName(filter);
    },
    maxInName: 1,
  },
  match_playlist: {
    getVisibleName: function (filter: MediaTrackerFilter, feed: MediaTracker) {
      return `Playlist ${getParameterFunc('visible_name')(filter)}`;
    },
    maxInName: 1,
  },
  match_channel: {
    getVisibleName: getParameterFunc('visible_name'),
    maxInName: 1,
  },
  match_network: {
    getVisibleName: getParameterFunc('visible_name'),
    maxInName: 1,
  },
  mentions: {
    getVisibleName: getParameterFunc('visible_name'),
    maxInName: 1,
  },
};

const getGoogleReviewFeedName = (feed: MediaTracker) => {
  const locationFilters = feed.media_tracker_filters.filter((mtFilter) => mtFilter.filter_type === 'match_location');
  const locationNames = locationFilters.map(getParameterFunc('name'));

  if (locationNames.length > 1) {
    return `Reviews for ${locationNames.length} locations`;
  }

  return `${locationNames.join(',')} reviews`;
};

const getLinkedinFeedName = (feed: MediaTracker) => {
  // Page name
  const pageFilters = feed.media_tracker_filters.filter((mtFilter) => mtFilter.filter_type === 'match_author');
  const pageNames = pageFilters.map(getParameterFunc('visible_name'));

  // Location filters
  const locationFilters = feed.media_tracker_filters.filter((mtFilter) => mtFilter.filter_type === 'match_location');
  const locationNames = locationFilters.map(getParameterFunc('name'));
  const locationIds = locationFilters.map(getParameterFunc('location_id'));
  let locationNamePart = locationNames.join(', ');

  if (locationNames.length > 2) {
    locationNamePart = `${locationNames.length} locations`;
  }

  if (locationIds.includes('global')) {
    locationNamePart = 'posts without a location';
  }

  // Locale filters
  const localeFilters = feed.media_tracker_filters.filter((mtFilter) => mtFilter.filter_type === 'match_locale');
  const localeNames = localeFilters.map(getParameterFunc('name'));
  const localeIds = localeFilters.map(getParameterFunc('locale_code'));
  let localeNamePart = localeNames.join(', ');

  if (localeNames.length > 2) {
    localeNamePart = `${localeNames.length} languages`;
  }

  if (localeIds.includes('global')) {
    localeNamePart = 'posts without a language';
  }

  // Both global
  if (localeIds.includes('global') && locationIds.includes('global')) {
    locationNamePart = 'posts without a language and a location';
    localeNamePart = '';
  }

  // => PageName, Location1, Location2 | Locale1, Locale2
  return [
    // page
    pageNames.join(', '),
    // location filters
    locationNamePart ? ', ' : '',
    locationNamePart,
    // locale filters
    localeNamePart ? ', ' : '',
    localeNamePart,
  ].join('');
};

export const getFeedName = (feed: MediaTracker) => {
  if (feed.name) {
    return feed.name;
  }

  switch (feed.service) {
    case 'google_review': {
      return getGoogleReviewFeedName(feed);
    }

    case 'linkedin': {
      return getLinkedinFeedName(feed);
    }

    default: {
      return legacyGetFeedName(feed);
    }
  }
};

const feedFilterOrder: MediaTrackerFilterParameterType[] = [
  'match_author',
  'match_business_author',
  'match_category_by_author',
  'match_channel',
  'match_location',
  'match_network',
  'match_playlist',
  'match_simple_author',
  'match_tag',
  'match_url',
  'mentions',
  'no_replies',
  'no_republished_media',
  'match_media_type',
  'pictures_only',
  'videos_only',
];

const feedFilterSorter = (filterA: MediaTrackerFilter, filterB: MediaTrackerFilter) => {
  const filterAIndex = feedFilterOrder.indexOf(filterA.filter_type);
  const filterBIndex = feedFilterOrder.indexOf(filterB.filter_type);

  // Last those that are not found
  if (filterAIndex === -1) return 1;
  if (filterBIndex === -1) return -1;
  return filterAIndex - filterBIndex;
};

// This is old logic copied from Newsroom
// Hopefully refactored to simpler logic in future
const legacyGetFeedName = (feed: MediaTracker) => {
  let previousFilterType = null as MediaTrackerFilterParameterType | null;

  return [...feed.media_tracker_filters].sort(feedFilterSorter).reduce(function (currentName, filter) {
    let typesSeparator;
    let filterType: any;

    if (!get(filterTypesForVisibleName, filter.filter_type)) {
      return currentName;
    }

    typesSeparator = previousFilterType && previousFilterType !== filter.filter_type ? ', ' : ' ';
    filterType = { ...get(filterTypesForVisibleName, filter.filter_type) };

    if (filterType.skipAll) {
      previousFilterType = filter.filter_type;
      return currentName;
    }

    if (+filterType.currentCountInName >= filterType.maxInName) {
      filterType.skipAll = true;
      previousFilterType = filter.filter_type;
      return currentName + typesSeparator + '+ others';
    }

    filterType.currentCountInName = (filterType.currentCountInName | 0) + 1;
    previousFilterType = filter.filter_type;
    return (currentName + typesSeparator + filterType.getVisibleName(filter, feed)).trim();
  }, '');
};

const getIsNewFeed = (createdAt: string): boolean => {
  const now = new Date();
  const newFeedLimitInSeconds = 15 * 60 * 1000;

  return now.getTime() - new Date(createdAt).getTime() <= newFeedLimitInSeconds;
};

type AutomatedFeedsListItemProps = {
  hasTwitterApiKey?: boolean;
  feed: MediaTracker;
  sections: Section[];
  hasError?: boolean;
  severity?: 'error' | 'warning';
  isAdmin: boolean;
  onPause: (mt: MediaTracker) => Promise<any>;
  onEnable: (mt: MediaTracker) => Promise<any>;
  onDelete: (mt: MediaTracker) => void;
};

const AutomatedFeedsListItem = ({
  hasTwitterApiKey,
  feed,
  sections,
  hasError,
  severity,
  isAdmin,
  onPause,
  onEnable,
  onDelete,
}: AutomatedFeedsListItemProps) => {
  const [isDetailVisible, setIsDetailVisible] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const siteUrl = useSiteUrl();

  // Memoize feed name forming
  const feedName = useMemo(() => getFeedName(feed), [feed]);

  const feedSectionSubscriptions = feed.media_tracker_subscriptions.filter(
    (mtSubscription) => mtSubscription.subscriber_type === 'Section'
  );

  const feedSections = feedSectionSubscriptions.map((mtSubscription) => ({
    feedSectionName: (sections || []).find((s) => s.id === mtSubscription.subscriber_id)?.name,
    feedSectionId: mtSubscription.subscriber_id,
    feedIsModerated: !mtSubscription?.parameters.some((param) => param.name === 'auto_publish'),
  }));

  const isNewFeed = getIsNewFeed(feed.created_at);
  const isEnabled = feed?.media_tracker_setting?.enabled;

  return (
    <div
      className={classnames(styles.item, severity, {
        [styles.itemWithDetails]: isDetailVisible,
        [styles.itemWithError]: !!hasError,
        [styles.itemPaused]: !isEnabled && feed.state !== 'deleted',
        [styles.itemDeleted]: feed.state === 'deleted',
      })}
    >
      {hasError && <span className={styles.errorIndicator} />}

      <div className={styles.mainRow} onClick={() => setIsDetailVisible(!isDetailVisible)}>
        <div className={classnames(styles.col, styles.nameCol)}>
          <div className={styles.content}>
            {isNewFeed && <span className={styles.feedBadge}>New</span>}

            <AutomatedFeedsListItemIcon service={feed.service} />

            <div className={styles.details}>
              {isAdmin ? (
                <a
                  href={`${config.flocklerNewsroomUrl}/${siteUrl}/media-trackers/${feed.id}`}
                  title={`ID: ${feed.id}`}
                  data-id={feed.id}
                  data-external-id={feed.external_feed_id}
                  className={`${styles.label} notranslate`}
                  onClick={(e) => {
                    e.stopPropagation();
                  }}
                >
                  {feedName}
                </a>
              ) : (
                <span
                  title={`ID: ${feed.id}`}
                  data-id={feed.id}
                  data-external-id={feed.external_feed_id}
                  className={styles.label}
                >
                  {feedName}
                </span>
              )}
              <span className={styles.sectionInfo}>
                {!feedSections.length && 'No section to save content into'}

                {feedSections.length > 0 &&
                  feedSections.map((feedSection) => (
                    <React.Fragment key={feedSection.feedSectionId}>
                      {feedSection.feedIsModerated ? (
                        <>
                          Moderate from Inbox:{' '}
                          <a
                            onClick={(e) => {
                              e.stopPropagation();
                            }}
                            href={`${config.flocklerNewsroomUrl}/${siteUrl}/sections/${feedSection.feedSectionId}/inbox`}
                            className="notranslate"
                          >
                            {feedSection.feedSectionName || `Section ${feedSection.feedSectionId}`}
                          </a>
                        </>
                      ) : (
                        <>
                          Section:{' '}
                          <a
                            onClick={(e) => {
                              e.stopPropagation();
                            }}
                            href={`${config.flocklerNewsroomUrl}/${siteUrl}/sections/${feedSection.feedSectionId}`}
                            className="notranslate"
                          >
                            {feedSection.feedSectionName || feedSection.feedSectionId}
                          </a>
                        </>
                      )}
                    </React.Fragment>
                  ))}
              </span>
            </div>
          </div>
        </div>

        {feed.state === 'deleted' && feed.updated_at && (
          <div className={classnames(styles.col, styles.deletedAtCol)}>
            <div className={styles.deletedAt}>
              Deleted{' '}
              <time dateTime={feed.updated_at} title={feed.updated_at}>
                {formatRelativeDistance(feed.updated_at)}
              </time>
            </div>
          </div>
        )}

        <div className={styles.col}>
          {feed.state !== 'deleted' && (
            <>
              {isEnabled && (
                <button
                  onClick={(e) => {
                    e.stopPropagation();
                    setIsLoading(true);

                    onPause(feed).finally(() => setIsLoading(false));
                  }}
                  className={styles.linkButton}
                  title="Pause feed from collecting new content"
                >
                  <span>
                    <IconPause className={styles.icon} /> {isLoading ? 'Pausing…' : 'Pause'}
                  </span>
                </button>
              )}

              {!isEnabled && (
                <button
                  onClick={(e) => {
                    e.stopPropagation();
                    setIsLoading(true);

                    onEnable(feed).finally(() => setIsLoading(false));
                  }}
                  className={classnames(styles.linkButton, styles.linkButtonSuccess)}
                  title={isLoading ? 'Enabling feed…' : 'Enable feed to collect new content'}
                >
                  <span>
                    <IconPlay className={styles.icon} /> {isLoading ? 'Enabling…' : 'Enable'}
                  </span>
                </button>
              )}

              <button
                className={styles.dangerButton}
                onClick={(e) => {
                  e.stopPropagation();
                  onDelete(feed);
                }}
                title="Delete this feed (and optionally all its content)"
              >
                <span>
                  <IconRemove className={styles.icon} /> Delete…
                </span>
              </button>
            </>
          )}

          <IconArrowRight className={styles.toggleIcon} />
        </div>
      </div>

      {hasError && (
        <div className={styles.errorRow}>
          {hasError && <span className={styles.errorIndicator} />}
          <AutomatedFeedError feed={feed} />
        </div>
      )}

      <div className={classnames(styles.summaryRow, { [styles.open]: isDetailVisible })}>
        {isDetailVisible && <AutomatedFeedsListItemSummary feed={feed} hasTwitterApiKey={hasTwitterApiKey} />}
      </div>
    </div>
  );
};

export default AutomatedFeedsListItem;
