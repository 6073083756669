import styles from './GardeLogo.module.scss';
import WebComponent from 'utils/web-component';

export default function GardeLogo() {
  return (
    <span className={styles.gardePromoContainer}>
      <WebComponent tag="fl-icon" icon="garde-text" width="80" height="auto" css="vertical-align: bottom;" />
      <div className={styles.gardePromo}>
        Includes <WebComponent tag="fl-icon" icon="garde-text" width="80" height="auto" css="position: relative; top: -1px;" />
        <br className={styles.gardePromo__lineBreak} />{' '}
        automatic moderation. <br />
        <WebComponent
          tag="fl-button"
          size="small"
          class="mt"
          href="https://flockler.com/garde-ai"
          target="_blank"
        >
          Read more
        </WebComponent>
      </div>
    </span>
  );
}
