interface NotificationProps {
  title: React.ReactNode;
  children: React.ReactNode;
  actions?: React.ReactNode;
  icon?: 'garde' | 'rocket' | 'attention' | 'sparkles' | '' | undefined;
  severity: 'promo' | 'success' | 'info' | 'warning' | 'error';
  dismissAction?: () => void | undefined;
}

const getNotificationBackground = (iconName?: string) => {
  switch (iconName) {
    case 'garde':
      return (
        <svg
          className="h-auto w-full"
          width="72"
          height="72"
          viewBox="0 0 72 72"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M49.7305 25.246C49.4965 17.7845 43.7045 11.5472 35.982 11.5472C28.3179 11.5472 22.5259 17.7845 22.2334 25.246L22.2333 26.12C22.2333 26.9421 22.2011 27.8052 22.1681 28.6882C22.0537 31.7495 21.9303 35.0509 23.1109 37.7203C25.5681 43.1415 30.775 43.6078 35.9819 43.6078C41.0718 43.6078 46.1031 43.2581 48.6773 38.07C50.0516 35.3314 49.9194 31.878 49.7973 28.6892C49.7635 27.8055 49.7304 26.9422 49.7304 26.12L49.7305 25.246ZM28.0839 25.2461C28.4349 15.1031 43.529 15.1031 43.88 25.2461L43.88 26.1201C43.88 26.9535 43.9058 27.7933 43.9318 28.6353C43.9838 30.3258 44.0362 32.0249 43.88 33.6981C43.5556 37.8454 39.9348 37.8124 36.7557 37.7834C36.494 37.781 36.2354 37.7786 35.9819 37.7786C35.7283 37.7786 35.4697 37.781 35.2081 37.7834C32.0289 37.8124 28.4081 37.8454 28.0838 33.6981C27.9666 32.0249 28.0059 30.3258 28.0449 28.6353C28.0644 27.7933 28.0838 26.9535 28.0838 26.1201L28.0839 25.2461Z"
            fill="currentColor"
          />
          <path
            d="M32.9976 29.2687V25.1882C32.9976 23.556 34.3432 22.3318 35.9228 22.2735C37.339 22.2213 38.5671 23.3873 38.806 24.7214C38.8306 24.8583 38.7555 24.9911 38.6315 25.0541L36.2836 26.2464C36.1313 26.3237 36.1313 26.5171 36.2836 26.5945L38.6622 27.8024C38.7762 27.8603 38.848 27.9773 38.848 28.1052V29.2687C38.848 30.9009 37.5024 32.125 35.9228 32.1833C34.3432 32.2416 32.9976 30.7843 32.9976 29.2687Z"
            fill="currentColor"
          />
          <path
            d="M16.9681 47.9196C16.032 48.8523 15.0374 49.8433 14.1014 50.7759C12.9898 51.8835 12.9313 53.8072 14.1014 54.9147C15.2715 55.964 17.0851 56.0806 18.2552 54.9147C19.1913 53.982 20.1858 52.9911 21.1219 52.0584C22.2335 50.9508 22.292 49.0272 21.1219 47.9196C19.8933 46.812 18.0797 46.7537 16.9681 47.9196Z"
            fill="currentColor"
          />
          <path
            d="M33.0562 53.4561C33.0562 54.7968 33.0562 56.1376 33.0562 57.5366C33.0562 59.0522 34.4018 60.5095 35.9814 60.4512C37.561 60.3929 38.9066 59.1688 38.9066 57.5366C38.9066 56.1959 38.9066 54.8551 38.9066 53.4561C38.9066 51.9405 37.561 50.4832 35.9814 50.5415C34.4018 50.5998 33.0562 51.8239 33.0562 53.4561Z"
            fill="currentColor"
          />
          <path
            d="M50.9003 51.9997C51.8363 52.9324 52.8309 53.9233 53.767 54.856C54.8786 55.9636 56.8092 56.0219 57.9208 54.856C58.9739 53.6902 59.0909 51.8831 57.9208 50.7172C56.9847 49.7846 55.9902 48.7936 55.0541 47.8609C53.9425 46.7533 52.0119 46.695 50.9003 47.8609C49.8472 49.085 49.7887 50.8338 50.9003 51.9997Z"
            fill="currentColor"
          />
        </svg>
      );
    case 'rocket':
      return (
        <svg
          className="h-auto w-4/5"
          width="72"
          height="72"
          viewBox="0 0 163 131"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            id="Vector"
            d="M105.885 97.5936C106.594 100.604 106.969 103.742 106.969 106.969C106.969 129.474 88.7244 147.719 66.2188 147.719V115.121M105.885 97.5936C131.245 79.0626 147.719 49.0952 147.719 15.2812C113.906 15.2827 83.9414 31.7569 65.412 57.1162M105.885 97.5936C94.2811 106.073 80.8167 112.158 66.2188 115.121M65.412 57.1162C62.4003 56.4067 59.2596 56.0312 56.0312 56.0312C33.5256 56.0312 15.2812 74.2756 15.2812 96.7812H47.8862M65.412 57.1162C56.9335 68.7199 50.8493 82.1839 47.8862 96.7812M66.2188 115.121C65.5164 115.264 64.8115 115.399 64.104 115.527C57.9445 110.643 52.3631 105.062 47.4791 98.902C47.6075 98.1925 47.7432 97.4856 47.8862 96.7812M32.6809 113.018C25.2116 118.59 20.375 127.496 20.375 137.531C20.375 139.14 20.4993 140.72 20.7388 142.261C22.2804 142.501 23.86 142.625 25.4688 142.625C35.504 142.625 44.4095 137.788 49.9815 130.319M112.062 61.125C112.062 66.7514 107.501 71.3125 101.875 71.3125C96.2486 71.3125 91.6875 66.7514 91.6875 61.125C91.6875 55.4986 96.2486 50.9375 101.875 50.9375C107.501 50.9375 112.062 55.4986 112.062 61.125Z"
            stroke="currentColor"
            strokeWidth="10.1875"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
        </svg>
      );
    case 'attention':
      return (
        <svg
          className="h-auto w-4/5"
          width="72"
          height="72"
          viewBox="0 0 163 124"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            id="Vector"
            d="M81.5 61.625V87.0938M142.625 82C142.625 115.758 115.258 143.125 81.5 143.125C47.7416 143.125 20.375 115.758 20.375 82C20.375 48.2416 47.7416 20.875 81.5 20.875C115.258 20.875 142.625 48.2416 142.625 82ZM81.5 107.469H81.5509V107.52H81.5V107.469Z"
            stroke="currentColor"
            strokeWidth="10.1875"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
        </svg>
      );
    case 'sparkles':
      return (
        <svg
          className="h-auto w-4/5"
          width="72"
          height="72"
          viewBox="0 0 163 148"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M66.648 108.013L61.125 127.344L55.602 108.013C52.7011 97.8603 44.7647 89.9238 34.6116 87.023L15.2812 81.5L34.6116 75.977C44.7647 73.0761 52.7012 65.1397 55.602 54.9866L61.125 35.6562L66.648 54.9866C69.5488 65.1397 77.4853 73.0762 87.6384 75.977L106.969 81.5L87.6384 87.023C77.4853 89.9238 69.5488 97.8603 66.648 108.013Z"
            stroke="currentColor"
            strokeWidth="10.1875"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
          <path
            d="M124.008 59.1862L122.25 66.2188L120.492 59.1862C118.439 50.9737 112.026 44.5613 103.814 42.5081L96.7812 40.75L103.814 38.9919C112.026 36.9387 118.439 30.5263 120.492 22.3138L122.25 15.2812L124.008 22.3138C126.061 30.5263 132.474 36.9387 140.686 38.9919L147.719 40.75L140.686 42.5081C132.474 44.5613 126.061 50.9737 124.008 59.1862Z"
            stroke="currentColor"
            strokeWidth="10.1875"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
          <path
            d="M114.74 139.686L112.062 147.719L109.385 139.686C107.864 135.123 104.283 131.542 99.7202 130.021L91.6875 127.344L99.7202 124.666C104.283 123.145 107.864 119.565 109.385 115.001L112.062 106.969L114.74 115.001C116.261 119.565 119.842 123.145 124.405 124.666L132.438 127.344L124.405 130.021C119.842 131.542 116.261 135.123 114.74 139.686Z"
            stroke="currentColor"
            strokeWidth="10.1875"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
        </svg>
      );
    default:
      return null;
  }
};

const variants: Record<NotificationBox['severity'], string> = {
  promo: 'bg-purple-lighter text-purple-dark',
  success: 'bg-secondary-lighter text-secondary-dark',
  info: 'bg-primary-lighter text-primary-dark',
  warning: 'bg-warning-lighter text-warning-dark',
  error: 'bg-error-lighter text-error-dark',
};

const bgVariants: Record<NotificationBox['severity'], string> = {
  promo: 'text-purple-light opacity-30',
  success: 'text-secondary-light opacity-30',
  info: 'text-primary-light opacity-20',
  warning: 'text-warning-light opacity-20',
  error: 'text-error-light opacity-[25%]',
};

export default function NotificationBox({
  title,
  children,
  actions,
  severity,
  icon,
  dismissAction,
}: NotificationProps) {
  const severityClassName = variants[severity];
  const severityBgSymbolClassName = bgVariants[severity];
  const backgroundImage = getNotificationBackground(icon);

  return (
    <aside
      className={`relative mb-6 flex overflow-hidden rounded-md p-4 text-sm leading-[1.2] shadow-md md:p-6 ${severityClassName}`}
    >
      {icon && (
        <div
          className={`absolute -bottom-14 -right-[5%] flex h-72 w-72 items-center justify-center sm:right-[2%] ${severityBgSymbolClassName}`}
        >
          {backgroundImage}
        </div>
      )}

      <div className="relative">
        {dismissAction && (
          <button
            className="absolute -right-2 -top-2 cursor-pointer p-1 text-neutral-dark transition hover:scale-125 lg:-right-1 lg:-top-1"
            onClick={dismissAction}
          >
            <svg
              className="h-5 w-5"
              fill="none"
              strokeWidth="1.5"
              stroke="currentColor"
              viewBox="0 0 24 24"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path strokeLinecap="round" strokeLinejoin="round" d="M6 18 18 6M6 6l12 12"></path>
            </svg>
          </button>
        )}

        <div className="md:pr-32 lg:pr-56">
          <h3 className="md_pr-0 pr-5 text-lg font-semibold md:text-xl">{title}</h3>
          <div className="mt-4 space-y-3 text-lg text-neutral-darker md:text-xl">{children}</div>
          {actions && <div className="mt-3">{actions}</div>}
        </div>
      </div>
    </aside>
  );
}
