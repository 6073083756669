import Page from 'components/Page';
import config from 'config';
import useCurrentSite from 'hooks/useCurrentSite';
import { Helmet } from 'react-helmet';
import { SectionsCreateFirstSection } from './components/SectionsCreateFirstSection';
import { SectionsHeader } from './components/SectionsHeader';
import { SectionsList } from './components/SectionsList';
import { SectionsNewSectionForm } from './components/SectionsNewSectionForm';
import { useSectionsPermissions } from './hooks/useSectionsPermissions';
import capitalize from 'lodash/capitalize';
import { useSectionsWithStats } from './hooks/useSectionsWithStats';
import WebComponent from 'utils/web-component';
import { withSiteLoaded } from 'components/WithSiteLoaded';
import PageLoader from 'components/Loader/PageLoader';
import Icon from 'components/Icon';
import { useLocation } from 'react-router-dom';
import NotificationBox from 'components/NotificationBox';

const usePathQuery = () => {
  return new URLSearchParams(useLocation().search);
};

const Sections = () => {
  const { id: siteId, site_url: siteUrl, site_type: siteType } = useCurrentSite();

  const query = usePathQuery();
  const intro = query.get('intro');

  const isStreamSite = siteType === 'stream';
  const entityName = isStreamSite ? 'stream' : 'section';
  const title = capitalize(`${entityName}s`);
  const sectionUrl = `${config.flocklerNewsroomUrl}/${siteUrl}/sections`;

  const { isReadonly } = useSectionsPermissions();
  const {
    sections,
    createSection,
    updateSection,
    deleteSection,
    reorderSection,
    loadMore,
    isReachingEnd,
    isLoadingMore,
  } = useSectionsWithStats(siteId, { entityName, pageSize: 50 });

  if (siteType === 'issue' || siteType === 'default') {
    window.location.href = `${config.flocklerNewsroomUrl}/${siteUrl}`;
    return <PageLoader />;
  }

  const hasNoSectionsCreated = sections.length === 0 && isReachingEnd && !isReadonly;

  return (
    <Page style={{ maxWidth: '52rem' }}>
      <Helmet>
        <title>{`Flockler \u203A ${title}`}</title>
      </Helmet>
      {hasNoSectionsCreated ? (
        <SectionsCreateFirstSection onSubmit={createSection} entityName={entityName} />
      ) : (
        <>
          <SectionsHeader heading={title} showIntro={!isStreamSite} />

          {!isReadonly && intro !== 'garde' && (
            <SectionsNewSectionForm onSubmit={createSection} entityName={entityName} />
          )}
          {intro === 'garde' && (
            <div className="mb-10">
              <NotificationBox severity="info" title={<>Let
                <WebComponent
                  tag="fl-icon"
                  icon="garde-text"
                  width="130"
                  height="auto"
                  class="relative top-[3px] m-[-4px] inline-block align-middle leading-none"
                  alt="Garde AI"
                  title="Garde AI"
                  css="color: inherit; margin: 0 .2em; position: relative; top: .08em;"
                />
                  moderate your content</>} icon="garde"
              >

                <p className="text-base">
                  In Sections view, you can see how much unmoderated content Sections have in their <b>Inbox</b> and
                  click that link to access Garde moderation.
                </p>
              </NotificationBox>
            </div>
          )}

          {sections.length > 1 && (
            <div className="mb-5">
              <a
                href={`${config.flocklerNewsroomUrl}/${siteUrl}/frontpage`}
                className="flex items-center space-x-1.5 text-sm font-semibold text-brand"
              >
                <Icon type="home" className="relative -top-px h-3 w-3" />
                <span>All content</span>
              </a>
            </div>
          )}

          <SectionsList
            sections={sections}
            entityName={entityName}
            sectionUrl={sectionUrl}
            onItemDelete={!isReadonly ? deleteSection : undefined}
            onItemUpdate={!isReadonly ? updateSection : undefined}
            onItemReorder={!isReadonly && !isStreamSite ? reorderSection : undefined}
          />

          {!isReachingEnd && sections.length > 0 && (
            <div className="flex justify-center">
              <WebComponent
                tag="fl-button"
                variant="primary"
                disabled={isLoadingMore}
                onClick={loadMore}
                size="small"
                className="mt-8"
              >
                <span className="px-6">Load more</span>
              </WebComponent>
            </div>
          )}
        </>
      )}
    </Page>
  );
};

export default withSiteLoaded(Sections);
