import React, { useEffect } from 'react';
import { connect, ConnectedProps } from 'react-redux';
import { RootState } from 'redux/store';
import { Helmet } from 'react-helmet';
import Page from 'components/Page';
import AccountDetailsForm from './components/AccountDetailsForm';
import AccountRelayAccount from './components/AccountRelayAccount';
import AccountSecurityForm from './components/AccountSecurityForm';
import AccountSocialMediaConnections from './components/AccountSocialMediaConnections';
import {
  getUserProfile,
  getUserLoadingState,
  getUserUpdatingState,
  getUserUpdatingPasswordState,
  getUserPasswordErrorState,
  fetchUser,
  updateUser,
  updateUserPassword,
} from 'concepts/user';

import styles from './Account.module.scss';
import Heading from 'components/Heading';
import UserNotificationSettings from './components/UserNotificationSettings';
import { getIsFeatureFlagLoaded, getRelayAccountFeatureFlag } from 'concepts/feature-flags';
import PageLoader from 'components/Loader/PageLoader';

type AccountProps = ConnectedProps<typeof connector>;

const Account = ({
  user,
  userPasswordError,
  isLoadingUser,
  isUpdatingUser,
  isUpdatingUserPassword,
  isRelayAccountFeatureFlagEnabled,
  isFeatureFlagLoaded,
  fetchUser,
  updateUser,
  updateUserPassword,
}: AccountProps) => {
  useEffect(() => {
    fetchUser();
  }, [fetchUser]);

  const showRelayAccount = user?.relay_user_id && isRelayAccountFeatureFlagEnabled;

  const accountSection = showRelayAccount ? (
    <section id="relay-account" className={styles.AccountSection}>
      <AccountRelayAccount userDetails={user} />
    </section>
  ) : (
    <>
      <section id="details" className={styles.AccountSection}>
        <Heading level="h2" type="secondary">
          Account details
        </Heading>
        <AccountDetailsForm
          updateUser={updateUser}
          userDetails={user}
          isLoadingUserDetails={isLoadingUser}
          isUpdatingUser={isUpdatingUser}
        />
      </section>

      <section id="security" className={styles.AccountSection}>
        <Heading level="h2" type="secondary">
          Security
        </Heading>
        <AccountSecurityForm
          updateUserPassword={updateUserPassword}
          userPasswordError={userPasswordError}
          isLoadingUserDetails={isLoadingUser}
          isUpdatingUserPassword={isUpdatingUserPassword}
        />
      </section>
    </>
  );

  return (
    <Page className={styles.AccountWrapper}>
      <Helmet>
        <title>Flockler {'\u203A'} My account</title>
      </Helmet>
      <Heading level="h1" type="primary">
        My account
      </Heading>

      <div className="mx-auto max-w-md md:max-w-3xl">
        {isFeatureFlagLoaded ? accountSection : <PageLoader />}

        <section id="social-accounts" className={styles.AccountSection}>
          <Heading level="h2" type="secondary">
            Connected social media accounts
          </Heading>
          <AccountSocialMediaConnections />
        </section>

        <section id="notification-settings" className={styles.AccountSection}>
          <Heading level="h2" type="secondary">
            Email preferences
          </Heading>
          <UserNotificationSettings isLoadingUserDetails={isLoadingUser} user={user} updateUser={updateUser} />
        </section>
      </div>
    </Page>
  );
};

const mapStateToProps = (state: RootState) => ({
  user: getUserProfile(state),
  isLoadingUser: getUserLoadingState(state),
  isUpdatingUser: getUserUpdatingState(state),
  isUpdatingUserPassword: getUserUpdatingPasswordState(state),
  userPasswordError: getUserPasswordErrorState(state),
  isRelayAccountFeatureFlagEnabled: getRelayAccountFeatureFlag(state),
  isFeatureFlagLoaded: getIsFeatureFlagLoaded(state),
});

const mapDispatchToProps = {
  fetchUser,
  updateUser,
  updateUserPassword,
};

const connector = connect(mapStateToProps, mapDispatchToProps);

export default connector(Account);
