// # Feature Flags concept
import { AppDispatch, AppGetState, RootState } from 'redux/store';
import * as api from 'services/api';
import { getCurrentSiteId } from 'concepts/site';
import { createSelector } from '@reduxjs/toolkit';

// # Types
export interface FeatureFlags {
  [key: string]: number | string | boolean;
}

// # Action types
const FETCH_FEATURE_FLAGS_STARTED = 'featureFlags/FETCH_FEATURE_FLAGS_STARTED';
const FETCH_FEATURE_FLAGS_SUCCESS = 'featureFlags/FETCH_FEATURE_FLAGS_SUCCESS';
const FETCH_FEATURE_FLAGS_FAILED = 'featureFlags/FETCH_FEATURE_FLAGS_FAILED';

interface FetchFeatureFlagsStartedAction {
  type: typeof FETCH_FEATURE_FLAGS_STARTED;
}

interface FetchFeatureFlagsSuccessAction {
  type: typeof FETCH_FEATURE_FLAGS_SUCCESS;
  payload: FeatureFlags;
}

interface FetchFeatureFlagsFailedAction {
  type: typeof FETCH_FEATURE_FLAGS_FAILED;
}

type FeatureFlagTypes = FetchFeatureFlagsStartedAction | FetchFeatureFlagsSuccessAction | FetchFeatureFlagsFailedAction;

// # Selectors
export const getFeatureFlags = (state: RootState) => state.featureFlags.featureFlags;
export const getSepaDebitFeatureFlag = (state: RootState) => !!getFeatureFlags(state).enable_sepa_debit;
export const getRelayAccountFeatureFlag = (state: RootState) => !!getFeatureFlags(state).relay_account_login;
export const getFeatureFlagStatus = (state: RootState) => state.featureFlags.status;
export const getIsFeatureFlagLoaded = createSelector(getFeatureFlagStatus, (status) => status === 'succeeded');

// # Actions
export const fetchFeatureFlags = (): any => (dispatch: AppDispatch, getState: AppGetState) => {
  const currentSiteId = getCurrentSiteId(getState());

  dispatch({ type: FETCH_FEATURE_FLAGS_STARTED });

  return api
    .fetchFeatureFlags(currentSiteId)
    .then((response) => {
      dispatch({ type: FETCH_FEATURE_FLAGS_SUCCESS, payload: response.data });
      return response.data;
    })
    .catch(() => {
      dispatch({ type: FETCH_FEATURE_FLAGS_FAILED });
    });
};

// # Reducer
export interface FeatureFlagsState {
  featureFlags: FeatureFlags;
  status: 'idle' | 'loading' | 'succeeded' | 'failed';
}

export const initialState: FeatureFlagsState = {
  featureFlags: {},
  status: 'idle',
};

export default function reducer(state = initialState, action: FeatureFlagTypes): FeatureFlagsState {
  switch (action.type) {
    case FETCH_FEATURE_FLAGS_SUCCESS: {
      return {
        ...state,
        status: 'succeeded',
        featureFlags: action.payload,
      };
    }

    case FETCH_FEATURE_FLAGS_STARTED: {
      return {
        ...state,
        status: 'loading',
      };
    }

    case FETCH_FEATURE_FLAGS_FAILED: {
      return {
        ...state,
        status: 'failed',
      };
    }

    default: {
      return state;
    }
  }
}
