import React from 'react';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import { showSuccess } from 'concepts/push-notifications';
import { fetchUser, getSocialMediaAccounts } from 'concepts/user';
import connectAccount from 'services/connect-account';
import { findLatestServiceAccount } from 'services/social-media-account';
import { fetchMediaTrackers, hasError, updateMediaTrackerAccountId } from 'concepts/media-tracker';
import { formatRelativeDistance } from 'services/time';
import useRefreshView from 'hooks/useRefreshView';
import useSiteId from 'hooks/useSiteId';
import useSiteUrl from 'hooks/useSiteUrl';
import useIsAiModerationEnabled from 'hooks/useIsAiModerationEnabled';
import Icon from 'components/Icon';
import styles from './AutomatedFeedsListItemSummary.module.scss';
import { RootState } from 'redux/store';
import { pathToSettingsApiKeys } from 'services/routes';
import WebComponent from 'utils/web-component';

const findParameterByName = (filter: MediaTrackerFilter, name: string) =>
  filter.parameters.find((parameter: MediaTrackerParameter) => parameter.name === name);

const SummaryItem = ({
  title,
  value,
  style,
}: {
  title: string | React.ReactNode;
  value: string | React.ReactNode;
  style?: React.CSSProperties;
}) => (
  <div className={styles.summaryItem} style={style}>
    <div className={styles.summaryItemTitle}>{title}</div>
    <div className={styles.summaryItemValue}>{value}</div>
  </div>
);

const FacebookPageNameSummary = ({ feed }: { feed: MediaTracker }) => {
  const matchAuthorFilter = feed.media_tracker_filters?.find((filter) => filter.filter_type === 'match_author');

  if (!matchAuthorFilter) {
    return null;
  }

  const pageName = findParameterByName(matchAuthorFilter, 'visible_name')?.value;
  const pageType = findParameterByName(matchAuthorFilter, 'type')?.value;
  const pageId = findParameterByName(matchAuthorFilter, 'name')?.value;

  return (
    <SummaryItem
      title={'Facebook Page'}
      value={
        <>
          {pageName}{' '}
          {pageType === 'page' && (
            <small className={styles.summaryItemValueExtra}>
              ID:
              <a href={`https://www.facebook.com/${pageId}`} target="_blank" rel="noopener noreferrer">
                {pageId}
              </a>
            </small>
          )}
        </>
      }
    />
  );
};

const AuthorsSummary = ({ feed }: { feed: MediaTracker }) => {
  const matchAuthorFilters = feed.media_tracker_filters?.filter((filter) => filter.filter_type === 'match_author');

  if (!matchAuthorFilters?.length) {
    return null;
  }

  const authorNames = matchAuthorFilters.reduce((sum: string[], acc: MediaTrackerFilter) => {
    const names = acc.parameters.filter((p) => p.name === 'name').map((p) => p.value);
    return [...sum, ...names];
  }, []);

  if (!authorNames.length) {
    return null;
  }

  const shouldIncludeAtSign = ['twitter', 'twitter_v2', 'tiktok'].includes(feed.service);
  const formattedAuthorNames = authorNames.map((name) => (shouldIncludeAtSign ? `@${name}` : name));

  return (
    <SummaryItem
      title={authorNames.length > 1 ? 'From users' : 'From user'}
      value={formattedAuthorNames.join(' or ')}
    />
  );
};

const OwnBusinessAuthorSummary = ({ feed }: { feed: MediaTracker }) => {
  const matchAuthorFilters = feed.media_tracker_filters?.filter((filter) => filter.filter_type === 'match_author');

  if (!matchAuthorFilters?.length) {
    return null;
  }

  const authorNames = matchAuthorFilters.reduce((sum: string[], acc: MediaTrackerFilter) => {
    let names = [];
    const visibleNames = acc.parameters.filter((p) => p.name === 'visible_name').map((p) => p.value);

    if (visibleNames.length) {
      names = visibleNames;
    } else {
      names = acc.parameters.filter((p) => p.name === 'name').map((p) => p.value);
    }

    return [...sum, ...names];
  }, []);

  if (!authorNames.length) {
    return null;
  }

  return <SummaryItem title={`From account${authorNames.length > 1 ? 's' : ''}`} value={authorNames.join(' or ')} />;
};

const BusinessAuthorsSummary = ({ feed }: { feed: MediaTracker }) => {
  const matchAuthorFilters = feed.media_tracker_filters?.filter(
    (filter) => filter.filter_type === 'match_business_author'
  );

  if (!matchAuthorFilters?.length) {
    return null;
  }

  const authorNames = matchAuthorFilters.reduce((sum: string[], acc: MediaTrackerFilter) => {
    let names = [];
    const visibleNames = acc.parameters.filter((p) => p.name === 'visible_name').map((p) => p.value);

    if (visibleNames.length) {
      names = visibleNames;
    } else {
      names = acc.parameters.filter((p) => p.name === 'name').map((p) => p.value);
    }

    return [...sum, ...names];
  }, []);

  if (!authorNames.length) {
    return null;
  }

  return <SummaryItem title={`From account${authorNames.length > 1 ? 's' : ''}`} value={authorNames.join(' or ')} />;
};

const SimpleAuthorsSummary = ({ feed }: { feed: MediaTracker }) => {
  const matchAuthorFilters = feed.media_tracker_filters?.filter(
    (filter) => filter.filter_type === 'match_simple_author'
  );

  if (!matchAuthorFilters?.length) {
    return null;
  }

  const authorNames = matchAuthorFilters.reduce((sum: string[], acc: MediaTrackerFilter) => {
    const names = acc.parameters.filter((p) => p.name === 'name').map((p) => p.value);
    return [...sum, ...names];
  }, []);

  if (!authorNames.length) {
    return null;
  }

  return (
    <SummaryItem title={authorNames.length > 1 ? 'From accounts' : 'From account'} value={authorNames.join(' or ')} />
  );
};

const AuthorKeywordsSummary = ({ feed }: { feed: MediaTracker }) => {
  const matchAuthorFilter = feed.media_tracker_filters?.find((filter) => filter.filter_type === 'match_author');

  if (!matchAuthorFilter) {
    return null;
  }

  const keywordFilters = matchAuthorFilter.parameters.filter((p) => p.name === 'keyword');

  if (!keywordFilters.length) {
    return null;
  }

  const keywordValues = keywordFilters.map((k) => k.value);
  const matchAnyKeywordParameter = findParameterByName(matchAuthorFilter, 'match_any_keyword')?.value;
  const matchAnyKeyword = !!parseInt(matchAnyKeywordParameter, 10);

  return (
    <SummaryItem
      title={keywordValues.length > 1 ? 'By keywords' : 'By keyword'}
      value={keywordValues.join(matchAnyKeyword ? ' or ' : ', ')}
    />
  );
};

const KeywordsSummary = ({ feed }: { feed: MediaTracker }) => {
  const matchTagFilter = feed.media_tracker_filters?.find((filter) => filter.filter_type === 'match_tag');

  if (!matchTagFilter) {
    return null;
  }

  const keywordFilters = matchTagFilter.parameters.filter((p) => p.name === 'name');

  if (!keywordFilters.length) {
    return null;
  }

  const keywordValues = keywordFilters.map((k) => k.value);
  const matchAnyKeywordParameter = findParameterByName(matchTagFilter, 'match_any_keyword')?.value;
  const matchAnyKeyword = !!parseInt(matchAnyKeywordParameter, 10);

  return (
    <SummaryItem
      title={keywordValues.length > 1 ? 'By keywords' : 'By keyword'}
      value={keywordValues.join(matchAnyKeyword ? ' or ' : ', ')}
    />
  );
};

const RssUrlSummary = ({ feed }: { feed: MediaTracker }) => {
  const urlFilter = feed.media_tracker_filters?.find((filter) => filter.filter_type === 'match_url');

  if (!urlFilter) {
    return null;
  }

  const url = findParameterByName(urlFilter, 'url')?.value;

  return <SummaryItem title={'RSS URL'} value={<span className={styles.wordWrap}>{url}</span>} />;
};

const FlickrUrlSummary = ({ feed }: { feed: MediaTracker }) => {
  const urlFilter = feed.media_tracker_filters?.find((filter) => filter.filter_type === 'match_url');

  if (!urlFilter) {
    return null;
  }

  const url = findParameterByName(urlFilter, 'url')?.value;

  return <SummaryItem title={'Flickr Source URL'} value={url} />;
};

const GoogleReviewSummary = ({ feed }: { feed: MediaTracker }) => {
  const locationFilters = feed.media_tracker_filters?.filter((filter) => filter.filter_type === 'match_location');

  if (!locationFilters?.length) {
    return null;
  }

  const locations = locationFilters.map((locationFilter) => ({
    name: findParameterByName(locationFilter, 'name')?.value,
    url: findParameterByName(locationFilter, 'location_url')?.value,
  }));

  return (
    <SummaryItem
      title={'Reviews for'}
      value={locations.map((loc, index) => (
        <>
          {loc.url ? (
            <a href={loc.url} target="_blank" rel="noreferrer noopener">
              {loc.name}
            </a>
          ) : (
            <span>{loc.name}</span>
          )}
          {index < locations?.length - 1 ? ', ' : ''}
        </>
      ))}
    />
  );
};

const LinkedInSummary = ({ feed }: { feed: MediaTracker }) => {
  const authorFilters = feed.media_tracker_filters?.filter((filter) => filter.filter_type === 'match_author');

  if (!authorFilters?.length) {
    return null;
  }

  const pageNames = authorFilters.map((authorFilter) => findParameterByName(authorFilter, 'visible_name')?.value);

  return <SummaryItem title={'From Page'} value={pageNames.join(', ')} />;
};

const LinkedInLocationsSummary = ({ feed }: { feed: MediaTracker }) => {
  const locationFilters = feed.media_tracker_filters?.filter((filter) => filter.filter_type === 'match_location');

  if (!locationFilters?.length) {
    return null;
  }

  const locationNames = (locationFilters || []).map(
    (locationFilter) => findParameterByName(locationFilter, 'name')?.value
  );

  const locationIds = (locationFilters || []).map(
    (locationFilter) => findParameterByName(locationFilter, 'location_id')?.value
  );

  if (locationIds.includes('global')) {
    return <SummaryItem title={'Locations'} value={'Not targeted to any location'} />;
  }

  return <SummaryItem title={'Locations'} value={locationNames.join(', ')} />;
};

const LinkedInLocaleSummary = ({ feed }: { feed: MediaTracker }) => {
  const localeFilters = feed.media_tracker_filters?.filter((filter) => filter.filter_type === 'match_locale');

  if (!localeFilters?.length) {
    return null;
  }

  const locationNames = (localeFilters || []).map((localeFilter) => findParameterByName(localeFilter, 'name')?.value);

  const locationIds = (localeFilters || []).map(
    (localeFilter) => findParameterByName(localeFilter, 'locale_code')?.value
  );

  if (locationIds.includes('global')) {
    return <SummaryItem title={'Languages'} value={'Not targeted to any language'} />;
  }

  return <SummaryItem title={'Languages'} value={locationNames.join(', ')} />;
};

const PinterestSummary = ({ feed }: { feed: MediaTracker }) => {
  const boardFilter = feed.media_tracker_filters?.find((filter) => filter.filter_type === 'match_category_by_author');
  if (boardFilter) {
    return (
      <>
        <SummaryItem title={'From Pinterest user'} value={findParameterByName(boardFilter, 'author_name')?.value} />
        <SummaryItem title={'From Pinterest board'} value={findParameterByName(boardFilter, 'category_name')?.value} />
      </>
    );
  }

  const authorFilter = feed.media_tracker_filters?.find((filter) => filter.filter_type === 'match_author');
  if (authorFilter) {
    return <SummaryItem title={'From Pinterest user'} value={findParameterByName(authorFilter, 'name')?.value} />;
  }

  return null;
};

const YoutubeSummary = ({ feed }: { feed: MediaTracker }) => {
  // Channel YouTube feed
  const channelFilter = feed.media_tracker_filters?.find((filter) => filter.filter_type === 'match_channel');
  if (channelFilter) {
    const channelKeywordFilter = findParameterByName(channelFilter, 'keyword')?.value;
    return (
      <>
        <SummaryItem title={'Youtube channel'} value={findParameterByName(channelFilter, 'visible_name')?.value} />
        {!!channelKeywordFilter && <SummaryItem title={'By keyword'} value={channelKeywordFilter} />}
      </>
    );
  }

  // Playlist YouTube feed
  const playlistFilter = feed.media_tracker_filters?.find((filter) => filter.filter_type === 'match_playlist');
  if (playlistFilter) {
    const playlistKeywordFilter = findParameterByName(playlistFilter, 'keyword')?.value;
    return (
      <>
        <SummaryItem title={'Youtube playlist'} value={findParameterByName(playlistFilter, 'visible_name')?.value} />
        {!!playlistKeywordFilter && <SummaryItem title={'By keyword'} value={playlistKeywordFilter} />}
      </>
    );
  }

  // Keyword YouTube feed
  return <KeywordsSummary feed={feed} />;
};

const formatFeedUpdateInterval = (seconds: number) => {
  const minutes = Math.floor(seconds / 60);
  const remainderSeconds = seconds % 60;

  // Custom format for one minute:
  // - every minute
  // - every 1 minute and 30 seconds
  if (minutes === 1) {
    return (
      <>
        {remainderSeconds ? '1 ' : ''}minute{remainderSeconds ? ` and ${remainderSeconds} seconds` : ''}
      </>
    );
  }

  if (minutes > 1) {
    return (
      <>
        {minutes} minutes{remainderSeconds ? ` and ${remainderSeconds} seconds` : ''}
      </>
    );
  }

  return <>{seconds} seconds</>;
};

const getFeedModerationName = (feed: MediaTracker, isAiModerationEnabled: boolean) => {
  const moderationParameterName = feed.media_tracker_subscriptions[0]?.parameters[0]?.name;

  switch (moderationParameterName) {
    case 'auto_publish':
      return 'Display Automatically';
    case 'ai_moderation':
      return isAiModerationEnabled ? (
        <>
          Moderated with{' '}
          <WebComponent
            tag="fl-icon"
            icon="garde-text"
            width="80"
            height="auto"
            class="relative top-[2px] m-[-4px] inline-block align-middle leading-none"
            alt="Garde AI"
          >
            Garde AI
          </WebComponent>
        </>
      ) : (
        'Saved to inbox for moderation'
      );
    default:
      return 'Saved to inbox for moderation';
  }
};

type AutomatedFeedsListItemSummaryProps = {
  feed: MediaTracker;
  fetchMediaTrackers: any;
  fetchUser: any;
  hasTwitterApiKey?: boolean;
  showSuccess: (message: string) => void;
  socialMediaAccounts?: UserSocialMediaAccount[];
  updateMediaTrackerAccountId: (feed: MediaTracker, accountId: number) => Promise<any>;
};

const AutomatedFeedsListItemSummary = ({
  feed,
  fetchMediaTrackers,
  fetchUser,
  hasTwitterApiKey,
  showSuccess,
  socialMediaAccounts,
  updateMediaTrackerAccountId,
}: AutomatedFeedsListItemSummaryProps) => {
  // Force render in 30 sec interval
  useRefreshView(30 * 1000);
  const siteId = useSiteId();
  const siteUrl = useSiteUrl();
  const isAiModerationEnabled = useIsAiModerationEnabled();

  const isDefaultSummaryVisible = ![
    'facebook',
    'linkedin',
    'pinterest',
    'instagram_graph_api',
    'yammer',
    'youtube',
  ].includes(feed.service);

  const hasSection = feed.media_tracker_subscriptions.some(
    (mtSubscription) => mtSubscription.subscriber_type === 'Section'
  );

  const onFeedAccountChange = () => {
    const latestServiceAccount = findLatestServiceAccount(feed.service, socialMediaAccounts);
    let isAccountChanged = false;

    const connectAccountCallback = () => {
      fetchUser()
        .then((user: User) => {
          const updatedAccount = findLatestServiceAccount(feed.service, user?.social_media_accounts);

          // Check that new account is connected
          if (
            !updatedAccount ||
            (latestServiceAccount && updatedAccount?.connected_at === latestServiceAccount?.connected_at)
          ) {
            return Promise.reject();
          }
          // Update account if it has been changed
          if (updatedAccount.id !== feed.social_media_account_id) {
            isAccountChanged = true;
            return updateMediaTrackerAccountId(feed, updatedAccount.id);
          }
        })
        .then(fetchMediaTrackers)
        .then(() => showSuccess(isAccountChanged ? 'Account changed' : 'Account updated'))
        .catch(() => console.log('Changing feed account failed'));
    };

    connectAccount(feed.service, connectAccountCallback);
  };

  let externalFeedPollingIntervalCopy;
  const isTwitterV2KeywordFeed =
    feed.service === 'twitter_v2' &&
    feed.media_tracker_filters?.some((filter) => filter.filter_type === 'match_tag') &&
    !feed.media_tracker_filters?.some((filter) => filter.filter_type === 'match_author');

  if (['google_review', 'instagram_basic_display', 'linkedin', 'mastodon', 'tiktok'].includes(feed.service)) {
    externalFeedPollingIntervalCopy = 'On average, we scan for fresh content every five minutes.';
  } else if (isTwitterV2KeywordFeed && hasTwitterApiKey) {
    externalFeedPollingIntervalCopy = 'We check for new tweets by the keyword every five minutes.';
  } else if (isTwitterV2KeywordFeed) {
    externalFeedPollingIntervalCopy = 'We check for new tweets by the keyword a few times per day.';
  } else if (feed.service === 'twitter_v2') {
    externalFeedPollingIntervalCopy = 'We check for new tweets by the account a few times per day.';
  }

  return (
    <div className={styles.summaryContent}>
      {hasSection && (
        <SummaryItem title={'Post visibility'} value={getFeedModerationName(feed, isAiModerationEnabled)} />
      )}

      {feed.service === 'facebook' && <FacebookPageNameSummary feed={feed} />}
      {feed.service === 'facebook' && <AuthorKeywordsSummary feed={feed} />}

      {feed.service === 'instagram_graph_api' && <OwnBusinessAuthorSummary feed={feed} />}
      {feed.service === 'instagram_graph_api' && <BusinessAuthorsSummary feed={feed} />}
      {feed.service === 'instagram_graph_api' && <SimpleAuthorsSummary feed={feed} />}
      {feed.service === 'instagram_graph_api' && <KeywordsSummary feed={feed} />}

      {feed.service === 'youtube' && <YoutubeSummary feed={feed} />}

      {feed.service === 'flickr' && <FlickrUrlSummary feed={feed} />}
      {feed.service === 'rss' && <RssUrlSummary feed={feed} />}
      {feed.service === 'pinterest' && <PinterestSummary feed={feed} />}
      {feed.service === 'google_review' && <GoogleReviewSummary feed={feed} />}

      {feed.service === 'linkedin' && <LinkedInSummary feed={feed} />}
      {feed.service === 'linkedin' && <LinkedInLocationsSummary feed={feed} />}
      {feed.service === 'linkedin' && <LinkedInLocaleSummary feed={feed} />}

      {isDefaultSummaryVisible && <AuthorsSummary feed={feed} />}
      {isDefaultSummaryVisible && <KeywordsSummary feed={feed} />}

      <SummaryItem
        style={{ zIndex: 2 }}
        title={
          <>
            Last Sync
            {feed.media_tracker_setting.enabled &&
              feed.media_tracker_setting.seconds_between_updates &&
              !hasError(feed) && (
                <>
                  <Icon type="help-circle" className={styles.tooltipIcon} />
                  <div className={styles.tooltip} style={{ left: '70px' }}>
                    {externalFeedPollingIntervalCopy ? (
                      externalFeedPollingIntervalCopy
                    ) : (
                      <>
                        We update fresh content every{' '}
                        <span className="inline-block">
                          {formatFeedUpdateInterval(feed.media_tracker_setting.seconds_between_updates)} on average
                        </span>
                      </>
                    )}
                  </div>
                </>
              )}
          </>
        }
        value={
          <>
            <time
              dateTime={feed.media_tracker_state.last_run_completed_at || feed.created_at}
              title={feed.media_tracker_state.last_run_completed_at || feed.created_at}
            >
              {formatRelativeDistance(feed.media_tracker_state.last_run_completed_at || feed.created_at)}
            </time>
            {isTwitterV2KeywordFeed && !hasTwitterApiKey && (
              <span className="block text-xs text-slate-500">
                Do you need live updates? Provide own <Link to={pathToSettingsApiKeys(siteUrl)}>Twitter API key</Link>{' '}
                for faster updates.
              </span>
            )}
          </>
        }
      />

      {feed.state === 'deleted' && feed.media_tracker_setting.deletion_audit ? (
        <>
          <SummaryItem
            title="Content deleted?"
            value={
              <span title={`Feed was deleted by User ID: ${feed.media_tracker_setting.deletion_audit.user_id}`}>
                {feed.media_tracker_setting.deletion_audit.delete_content ? 'Yes' : 'No'}
              </span>
            }
          />
        </>
      ) : null}

      {['facebook', 'instagram_graph_api', 'twitter', 'tiktok', 'youtube'].includes(feed.service) && (
        <SummaryItem
          title={'Connected account'}
          value={
            <div className={styles.account} title={`${feed.social_media_account_id}`}>
              <span>
                {feed.social_media_account_name ? (
                  <>
                    {['twitter', 'tiktok'].includes(feed.service) && '@'}
                    {feed.social_media_account_name}
                  </>
                ) : null}
              </span>{' '}
              {feed.connecting_user && feed.connecting_user.fullname !== feed.social_media_account_name ? (
                <span className={styles.accountOwner}>
                  by <span title={`User ID: ${feed.connecting_user.id}`}>{feed.connecting_user.fullname}</span>
                </span>
              ) : null}
              <div>
                {feed.state !== 'deleted' && feed.service !== 'tiktok' && (
                  <button className={styles.linkButton} onClick={onFeedAccountChange}>
                    Change account
                  </button>
                )}
              </div>
            </div>
          }
        />
      )}

      {siteId === 8476 && (
        <SummaryItem
          title="Feed UUID"
          value={
            <small style={{ whiteSpace: 'normal', wordBreak: 'break-word' }}>
              <code>{feed.uuid}</code>
            </small>
          }
        />
      )}
    </div>
  );
};

const mapStateToProps = (state: RootState) => ({ socialMediaAccounts: getSocialMediaAccounts(state) });

const mapDispatchToProps = {
  fetchUser,
  fetchMediaTrackers,
  updateMediaTrackerAccountId,
  showSuccess,
};

export default connect(mapStateToProps, mapDispatchToProps)(AutomatedFeedsListItemSummary);
